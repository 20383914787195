// helpers
import { ApiHelper } from 'shared/helpers/api';
import { getAdPlatformURL, ROUTES } from 'shared/api/urls';
import { getDeviceType } from 'shared/components/ResponsiveLoader/helpers';

// constants
import { RENDER_MODE } from 'shared/constants';

const getPlatformIdentifier = () => {
  const serverRenderMode = getDeviceType();

  return serverRenderMode === RENDER_MODE.DESKTOP ? 'dweb' : 'mweb';
};

export interface SearchPageProps {
  headers: Record<string, string>;
  pageName: string;
}

export const getSearchPageData = ({
  headers,
  pageName
}: SearchPageProps): Promise<any> => {
  const baseUrl = getAdPlatformURL(ROUTES.AD_PLATFORM_API);

  const params = new URLSearchParams({
    page_type: pageName,
    page_section: '*',
    platform: getPlatformIdentifier()
  });

  const url = `${baseUrl}?${params.toString()}`;
  return ApiHelper(url, { headers }).then(async (response: Response) => {
    if (response.ok) {
      try {
        return await response.json();
      } catch {
        return Promise.reject();
      }
    }
    return Promise.reject();
  });
};
