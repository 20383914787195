export const SEARCH_TYPE = {
  MANUAL: 'ManualSearch',
  AUTO_COMPLETE: 'SearchedFromSuggestions',
  RECENT_SEARCH: 'SearchedFromHistory',
  SEARCH_SUGGESTION: 'Suggestions',
};

export const SEARCHES_KEY = 'RECENT_SEARCHES_V3';
export const MOBILE_SUGGESTION_LINE_SIZE = 3;
export const MOBILE_MAX_WIDGET_ITEMS = 10;
export const DESKTOP_MAX_RECENT_SEARCHES = 3;
export const DESKTOP_MAX_TRENDING_SEARCHES = 5;
export const DN_WIDGETS_EXPERIMENT_NAME = 'search_dn_widgets';
export const DN_WIDGETS_PAGE_NAME = 'nf-search-all';

export const TEXTS = {
  CANCEL: 'Cancel',
  RECENT_SEARCHES: 'Recent Searches',
  CLEAR: 'Clear',
  OOPS: 'Something went wrong!!!',
  NO_RESULTS_FOUND: 'No Results for ',
};

export const DEVICE_TYPE = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};
